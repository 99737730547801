import {
  ButtonBlue,
  ButtonYellowFill,
  ButtonYellowOutline,
} from "components/common/CommonStyles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import Footer from "../Footer";
import Header from "../Header";
import "./videoInterview.css";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InterviewBoxStyled, InterviewPageBox } from "../InterviewStyled";
import { ToastStyles } from "components/common/ToastStyle";
import { toast, Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import ReactPlayer from "react-player";
import { AudioProcessor } from "../AudioProcessor";
export const VideoPage1 = () => {
  const [recordvideo, setRecordvideo] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const params = useParams();

  // webcam
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationRef = useRef(null);
  const canvasRef = useRef(null);
  const [audioProcessor, setAudioProcessor] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [animationFrameId, setAnimationFrameId] = useState(null);
  const [capturing, setCapturing] = useState(false);
  const recordedChunks = useRef([]);
  const videoConstraints = {
    height: 350,
    facingMode: "user",
    borderRadius: "20px",
  };
  const [video, setvideo] = useState("");
  const [testvideo, setTestvideo] = useState("");
  const name = Cookies.get("name");
  const role = Cookies.get("role");
  // console.log(video);
  // console.log("LocalStorage", localStorage);

  const userID = localStorage.getItem("userId");
  // console.log("user ID:", userID);
  useEffect(() => {
    setAudioProcessor(new AudioProcessor());
  }, []);
  // useEffect(() => {
  //   console.log("Webcam ref current:", webcamRef.current); // Log to check if the ref is set
  //   const stream = webcamRef.current?.stream;
  //   console.log("Stream available:", stream); // Check if stream is available
  //   if (stream && !audioContextRef.current) {
  //     console.log("Stream is active:", stream.active); // Check if the stream is active
  //     const audioContext = new AudioContext();
  //     const source = audioContext.createMediaStreamSource(stream);
  //     const analyser = audioContext.createAnalyser();
  //     analyser.fftSize = 64;
  //     source.connect(analyser);
  //     audioContextRef.current = audioContext;
  //     analyserRef.current = analyser;
  //     console.log("Audio context and analyser setup completed"); // Confirm setup
  //     draw();
  //   }
  // }, [webcamRef.current?.stream]);
  const handleUserMedia = (stream) => {
    console.log("Stream ready:", stream);
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 64;
    source.connect(analyser);
    audioContextRef.current = audioContext;
    analyserRef.current = analyser;

    draw();
  };

  const draw = useCallback(() => {
    if (!analyserRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    console.log("Buffer Length:", bufferLength); // Debugging line

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const drawVisual = () => {
      animationRef.current = requestAnimationFrame(drawVisual);
      analyserRef.current.getByteFrequencyData(dataArray);

      // console.log("Data Array:", dataArray); // Debugging line

      ctx.clearRect(0, 0, WIDTH, HEIGHT); // Clear the previous frame
      ctx.fillStyle = "rgb(0, 0, 255)";
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      const numBars = 3;
      const barGap = WIDTH * 0.1; // Set the gap between the bars
      const barWidth = (WIDTH - numBars * barGap) / numBars;
      let barHeight;
      let x = (WIDTH - (numBars * barWidth + (numBars - 1) * barGap)) / 2;
      const maxBarHeight = HEIGHT * 0.4;
      ctx.lineCap = "round";

      for (let i = 0; i < numBars; i++) {
        barHeight = (dataArray[i] * maxBarHeight) / 256;
        let y = (HEIGHT - barHeight) / 2;

        ctx.fillStyle = "rgb(255, 255, 255)"; // White bars
        ctx.beginPath();
        ctx.rect(x, y, barWidth, barHeight); // Draw the rectangle for the bar
        ctx.fill();

        x += barWidth + barGap; // Increase x by the width of the bar and the gap
      }
    };

    drawVisual();
  }, []);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close().then(() => {
          console.log("AudioContext closed successfully.");
        });
      }
      // Ensure all media tracks are stopped
      if (webcamRef.current && webcamRef.current.stream) {
        webcamRef.current.stream.getTracks().forEach((track) => {
          track.stop();
          console.log("Track stopped on cleanup:", track.kind);
        });
      }
    };
  }, []);

  const canvasStyles = {
    width: "3vw", // Full width of the container to align it properly
    height: "4vh", // Fixed height for visual consistency
    marginTop: "10px", // Space between the webcam and the visualizer
    borderRadius: "50%", // Optional: rounded corners for the visualizer
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Slightly transparent background
    position: "relative", // Position over the video
    left: "-7%",
    transform: "translateX(-50%)", // Center horizontally
    pointerEvents: "none", // Make sure it doesn't interfere with video interaction
  };
  const handleStartCaptureClick = async () => {
    if (!audioProcessor) {
      console.error("Audio processor is not initialized.");
      return;
    }
    setRunning(true);
    toast.success("Recording Started", ToastStyles);
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
          autoGainControl: true,
        },
        video: true,
      })
      .then(async (stream) => {
        // Process audio stream for noise reduction
        const processedAudioStream =
          await audioProcessor.getProcessedAudioStream();
        setAudioStream(processedAudioStream);
        const videoStream = webcamRef.current.stream;
        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...processedAudioStream.getAudioTracks(),
        ]);
        mediaRecorderRef.current = new MediaRecorder(combinedStream);
        console.log("stream", stream);
        mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
          recordedChunks.current.push(event.data);
        });
        mediaRecorderRef.current.addEventListener("stop", () => {
          const videoBlob = new Blob(recordedChunks.current, {
            type: "video/webm",
          });
          setvideo(videoBlob);
          recordedChunks.current = [];
          setTestvideo(URL.createObjectURL(videoBlob));
        });
        mediaRecorderRef.current.start();
        setCapturing(true);
      });
  };
  const handleStopCaptureClick = () => {
    setRunning(false);
    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.ondataavailable = (e) => {
      const videoURL = URL.createObjectURL(e.data);
      setvideo(videoURL);
      console.log("Video URL:", videoURL);
    };
    console.log("Recording stopped.");

    // Stop all tracks on the media stream
    if (webcamRef.current && webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach((track) => {
        track.stop();
        console.log("Track stopped:", track.kind);
      });
    }
    // Stop all tracks and dispose of audio processing resources
    if (audioProcessor) {
      audioProcessor.dispose();
    }
    setCapturing(false);
    setRecordvideo(true);
  };
  useEffect(() => {
    return () => {
      // Cleanup on component unmount
      if (audioProcessor) {
        audioProcessor.dispose();
      }
    };
  }, []);
  const recordagainstart = () => {
    setvideo([]);
    setTestvideo([]);
    handleClose();
  };
  const [time, setTime] = useState(0);

  const [running, setRunning] = useState(false);
  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
      setTime(0);
    }
    return () => clearInterval(interval);
  }, [running]);

  const [mediaShow, setMediaShow] = useState(false);

  const checkPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { noiseSuppression: true, echoCancellation: true },
        video: true,
      });
      console.log(stream);
      console.log("Accessed");
      setMediaShow(false);
      // Use the stream if needed
    } catch (err) {
      console.log(err);
      setMediaShow(true);
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  // console.log("Media Show:", mediaShow);
  // console.log("Test Video: ", testvideo);
  // const jobdetails = localStorage.getItem("jobDetails");
  const jobDetails = JSON.parse(localStorage.getItem("jobDetails"));
  const isCorporate = jobDetails?.creator_type === "corporate";
  return (
    <>
      <Modal show={mediaShow} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>
            <p className="text-center">Media Access not Found</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-5">
          <p className="text-center">
            Camera & MicroPhone Access not found Please Allow the permissions
            and Refresh the page to continue.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBlue
            onClick={() => {
              if (role === "interviewee") {
                navigate(-2);
              }
            }}
            className="w-100"
          >
            Back
          </ButtonBlue>
        </Modal.Footer>
      </Modal>
      <Toaster />
      <Header />
      <InterviewBoxStyled className="scroller interview-form h-70 padding-0">
        <InterviewPageBox className=" px-5">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h5> Are you sure you want to record again?</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>
                {" "}
                Do take note that this will require you to re-do the entire
                interview from question 1. Press the button Record Again to
                restart, or you can close this window and proceed to submit your
                responses.
              </h6>
            </Modal.Body>
            <Modal.Footer>
              <ButtonYellowOutline
                variant="secondary"
                onClick={recordagainstart}
              >
                Record Again
              </ButtonYellowOutline>
              <ButtonYellowFill width="120px" onClick={handleClose}>
                Close
              </ButtonYellowFill>
            </Modal.Footer>
          </Modal>
          <div className="col-12">
            {recordvideo ? (
              <>
                <div className="section-1 my-3 w-100 d-flex flex-column px-3">
                  <div className="d-flex justify-content-center mt-3">
                    <div className="col-md-6">
                      <div className="px-3 d-flex justify-content-center flex-column">
                        <h5 className="text-center">Hey! {name}</h5>
                        <h6 className="text-center">Check Your Video</h6>
                        <div className=" video-player pb-4">
                          <ReactPlayer
                            url={testvideo}
                            playing={false}
                            muted={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            playsinline={true}
                          />
                        </div>
                        <div className="d-flex justify-content-center my-2">
                          {!isCorporate && (
                            <ButtonYellowOutline
                              onClick={() => {
                                setRecordvideo(false);
                              }}
                              style={{ width: "400px" }}
                            >
                              Record Again
                            </ButtonYellowOutline>
                          )}
                        </div>
                        <div className="d-flex justify-content-center my-3">
                          <ButtonYellowFill
                            onClick={() =>
                              navigate(
                                `/interview/video-interview2/${params.id}`
                              )
                            }
                            width="400px"
                          >
                            Okay, Let's Proceed
                          </ButtonYellowFill>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="section-1 d-flex flex-column px-3 ">
                  <h5 className="text-center">
                    Test your Audio video to start the interview process
                  </h5>
                  <h6 className="text-center">
                    Describe the Environment you are in.
                  </h6>
                  <div
                    style={{ width: "fit-content" }}
                    className="video-cam-box d-flex mx-auto"
                  >
                    {" "}
                    <div className="video-cam">
                      <div className="Container">
                        <div className="d-flex justify-content-center main-video">
                          <Webcam
                            style={{ objectFit: "cover" }}
                            height={350}
                            width="fit-content"
                            audio={true}
                            muted={true}
                            mirrored={true}
                            ref={webcamRef}
                            videoConstraints={videoConstraints}
                            onUserMedia={handleUserMedia}
                          />
                          <canvas ref={canvasRef} style={canvasStyles}></canvas>
                        </div>
                        <div className="d-flex justify-content-center my-2"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex mx-auto justify-content-between prep-time mt-1"
                    style={{ width: "600px" }}
                  >
                    <h6>Preparation</h6>
                    <h6>(Time : {time}s)</h6>
                  </div>
                  <div className="d-flex justify-content-center my-1">
                    {capturing ? (
                      <ButtonYellowFill
                        onClick={() => {
                          handleStopCaptureClick();
                        }}
                        width="200px"
                        className="bg-red h-40"
                      >
                        Stop Recording
                      </ButtonYellowFill>
                    ) : (
                      <ButtonYellowFill
                        onClick={handleStartCaptureClick}
                        width="200px"
                        className="bg-green h-40"
                      >
                        Record Answer
                      </ButtonYellowFill>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </InterviewPageBox>
        <Footer />
      </InterviewBoxStyled>
    </>
  );
};
