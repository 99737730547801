// import React, { useEffect, useMemo, useRef } from "react";
// import { useFrame } from "@react-three/fiber";
// import { AudioAnalyser, MathUtils, Mesh, ShaderMaterial, Vector2 } from "three";
// import { vertexShader } from "./vertexShader";
// import { fragmentShader } from "./fragmentShader";
// // import vertexShader from "!!raw-loader!./vertexShader.glsl";
// // import fragmentShader from "!!raw-loader!./fragmentShader.glsl";

// const AudioBlob = ({ sound, play }) => {
//   const mesh = useRef(null);
//   const analyserRef = useRef(null);

//   const uniforms = useMemo(
//     () => ({
//       u_resolution: {
//         value: new Vector2(window.innerWidth, window.innerHeight),
//       },
//       u_time: {
//         value: 0.0,
//       },
//       u_intensity: {
//         value: 0.3,
//       },
//       u_frequency: {
//         value: 0.0,
//       },
//     }),
//     []
//   );

//   // Handle window resize
//   useEffect(() => {
//     const handleResize = () => {
//       uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, [uniforms]);

//   useEffect(() => {
//     if (sound.current && !analyserRef.current) {
//       analyserRef.current = new AudioAnalyser(sound.current, 32);
//       console.log("AudioBlob: Analyzer created.");
//     }

//     return () => {
//       analyserRef.current = null;
//       console.log("AudioBlob: Analyzer cleaned up.");
//     };
//   }, [sound]);

//   useFrame(({ clock }) => {
//     if (!mesh.current) return;

//     const material = mesh.current.material;
//     const elapsedTime = clock.getElapsedTime();
//     material.uniforms.u_time.value = elapsedTime;
//     mesh.current.rotation.x = elapsedTime * 0.1;
//     mesh.current.rotation.z = elapsedTime * 0.1;

//     if (analyserRef.current && play) {
//       const frequency = analyserRef.current.getAverageFrequency();
//       console.log("AudioBlob: Current frequency:", frequency);
//       material.uniforms.u_frequency.value = frequency;

//       const scale = 0.75 + frequency / 1000;
//       console.log("AudioBlob: Calculated scale:", scale);
//       mesh.current.scale.set(scale, scale, scale);
//     } else {
//       console.log("AudioBlob: Not playing or no analyser available.");
//     }
//   });

//   // Add debug logging in useFrame
//   // useFrame((state) => {
//   //   const { clock } = state;
//   //   if (mesh.current) {
//   //     const material = mesh.current.material;
//   //     material.uniforms.u_time.value = clock.getElapsedTime();
//   //     mesh.current.rotation.x = clock.getElapsedTime() * 0.1;
//   //     mesh.current.rotation.z = clock.getElapsedTime() * 0.1;
//   //     // Debug logging for audio analysis
//   //     if (analyserRef.current && play) {
//   //       mesh.current.rotation.y = clock.getElapsedTime() * 0.1;
//   //       material.uniforms.u_time.value = clock.getElapsedTime();
//   //       material.uniforms.u_frequency.value =
//   //         analyserRef.current.getAverageFrequency();
//   //       mesh.current.scale.x =
//   //         0.75 + analyserRef.current.getAverageFrequency() / 1000;
//   //       mesh.current.scale.y =
//   //         0.75 + analyserRef.current.getAverageFrequency() / 1000;
//   //       mesh.current.scale.z =
//   //         0.75 + analyserRef.current.getAverageFrequency() / 1000;

//   //       // const frequency = analyserRef.current.getAverageFrequency();
//   //       // console.log('Current frequency:', frequency);
//   //       // material.uniforms.u_frequency.value = MathUtils.lerp(
//   //       //   material.uniforms.u_frequency.value,
//   //       //   frequency / 255.0, // Normalize to 0-1 range
//   //       //   0.1 // Smoothing factor
//   //       // );
//   //       // material.uniforms.u_intensity.value = MathUtils.lerp(
//   //       //   material.uniforms.u_intensity.value,
//   //       //   frequency / 255.0, // Normalize to 0-1 range
//   //       //   0.1 // Smoothing factor
//   //       // );
//   //       // } else {
//   //       //   console.log('Audio analysis not available:', {
//   //       //     hasAnalyser: !!sound.current?.analyser,
//   //       //     isPlaying: play
//   //       //   });
//   //       // material.uniforms.u_frequency.value = MathUtils.lerp(material.uniforms.u_frequency.value, 0, 0.1);
//   //     }
//   //   }
//   // });
//   // useFrame(({ clock }) => {
//   //   if (!mesh.current) return;

//   //   const material = mesh.current.material;
//   //   const elapsedTime = clock.getElapsedTime();
//   //   material.uniforms.u_time.value = elapsedTime;
//   //   mesh.current.rotation.x = elapsedTime * 0.1;
//   //   mesh.current.rotation.z = elapsedTime * 0.1;

//   //   if (analyserRef.current && play) {
//   //     const frequency = analyserRef.current.getAverageFrequency();
//   //     console.log("AudioBlob : Audio frequency:", frequency);
//   //     material.uniforms.u_frequency.value = frequency;

//   //     const scale = 0.75 + frequency / 1000;
//   //     mesh.current.scale.set(scale, scale, scale);
//   //   }
//   // });

//   return (
//     <mesh
//       ref={mesh}
//       scale={0.75}
//       position={[0, 0, 0]}
//       // onClick={onClick}
//     >
//       <icosahedronGeometry args={[4, 30]} />
//       <shaderMaterial
//         vertexShader={vertexShader}
//         fragmentShader={fragmentShader}
//         uniforms={uniforms}
//         wireframe={false}
//         toneMapped={true}
//       />
//     </mesh>
//   );
// };

// export default AudioBlob;
import React, { useEffect, useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { AudioAnalyser, Vector2 } from "three";
import { vertexShader } from "./vertexShader";
import { fragmentShader } from "./fragmentShader";

const AudioBlob = ({ sound, play }) => {
  const mesh = useRef(null);
  const analyserRef = useRef(null);

  const uniforms = useMemo(
    () => ({
      u_resolution: {
        value: new Vector2(window.innerWidth, window.innerHeight),
      },
      u_time: { value: 0.0 },
      u_intensity: { value: 0.3 },
      u_frequency: { value: 0.0 },
    }),
    []
  );

  useEffect(() => {
    const handleResize = () => {
      uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [uniforms]);

  useEffect(() => {
    if (sound.current && !analyserRef.current) {
      const audioContext = new AudioContext();

      analyserRef.current = new AudioAnalyser(sound.current, 32);
      console.log("Analyzer created:", analyserRef.current);
    }

    return () => {
      if (analyserRef.current) {
        analyserRef.current = null;
      }
    };
  }, [sound.current]);

  useFrame((state) => {
    const { clock } = state;
    if (mesh.current) {
      const material = mesh.current.material;
      material.uniforms.u_time.value = clock.getElapsedTime();
      mesh.current.rotation.x = clock.getElapsedTime() * 0.1;
      mesh.current.rotation.z = clock.getElapsedTime() * 0.1;
      // Debug logging for audio analysis
      if (analyserRef.current && play) {
        mesh.current.rotation.y = clock.getElapsedTime() * 0.1;
        material.uniforms.u_time.value = clock.getElapsedTime();
        material.uniforms.u_frequency.value =
          analyserRef.current.getAverageFrequency();
        mesh.current.scale.x =
          0.75 + analyserRef.current.getAverageFrequency() / 1000;
        mesh.current.scale.y =
          0.75 + analyserRef.current.getAverageFrequency() / 1000;
        mesh.current.scale.z =
          0.75 + analyserRef.current.getAverageFrequency() / 1000;

        // const frequency = analyserRef.current.getAverageFrequency();
        // console.log('Current frequency:', frequency);
        // material.uniforms.u_frequency.value = MathUtils.lerp(
        //   material.uniforms.u_frequency.value,
        //   frequency / 255.0, // Normalize to 0-1 range
        //   0.1 // Smoothing factor
        // );
        // material.uniforms.u_intensity.value = MathUtils.lerp(
        //   material.uniforms.u_intensity.value,
        //   frequency / 255.0, // Normalize to 0-1 range
        //   0.1 // Smoothing factor
        // );
        // } else {
        //   console.log('Audio analysis not available:', {
        //     hasAnalyser: !!sound.current?.analyser,
        //     isPlaying: play
        //   });
        // material.uniforms.u_frequency.value = MathUtils.lerp(material.uniforms.u_frequency.value, 0, 0.1);
      }
    }
  });

  return (
    <mesh ref={mesh} scale={0.75}>
      <icosahedronGeometry args={[4, 30]} />
      <shaderMaterial
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={uniforms}
        wireframe={false}
        toneMapped
      />
    </mesh>
  );
};

export default AudioBlob;
