import {
  ButtonYellowFill,
  ButtonYellowOutline,
} from "components/common/CommonStyles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import Footer from "../Footer";
import Header from "../Header";
import { InterviewBoxStyled, InterviewPageBox } from "../InterviewStyled";
import Feedback from "./Feedback";
import FinalReviewAns from "./FinalReviewAns";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Webcam from "react-webcam";
import {
  GetSingleInterview,
  InterviewAnalysis,
  getAllVideos,
  GetStudentsDetails,
} from "../../../axios/apis";
import { webmFixDuration } from "webm-fix-duration";
import "./videoInterview.css";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";
import axios from "axios";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux"; // Import Redux selector
import { AudioProcessor } from "../AudioProcessor";
import WebcamWithDetection from "./WebcamWithDetection";
import AudioBlobVisualizer from "../../../components/AudioVisualizer/AudioBlobVisualizer";

export const VideoPage2 = () => {
  const [time, setTime] = useState(0);
  const [questionNo, setQuestionNo] = useState(1);
  const [recordedAnsno, setRecordedAnsNo] = useState(1);
  const [recordvideo, setRecordvideo] = useState(false);
  const [show, setShow] = useState(false);
  const [submitpage, setSubmitpage] = useState(false);
  const [feedbackpage, setFeedbackpage] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [running, setRunning] = useState(false);
  const [videoreplay, setvideoReplay] = useState(false);
  const [recordbtn, setrecordbtn] = useState(false);
  const [questionduration, setQuestionDuration] = useState(5000);
  const [video, setvideo] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  let urlsLength = 0;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [questions, setquestions] = useState([]);
  const [videoLoaded, setVideoLoaded] = useState(true);
  const [testvideo, setTestvideo] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [ThankyouModal, setThankyouModal] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const name = Cookies.get("name");
  const role = Cookies.get("role");
  const InterviewProRole = Cookies.get("InterviewProRole");
  const logintype = Cookies.get("logintype");
  const webcamRef = useRef(null);
  const recordedChunks = useRef([]);
  const mediaRecorderRef = useRef(null);
  const videoplayerRef = useRef(null);
  const videoQuestion = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [starttime, setStartTime] = useState(Date.now());
  const [audioProcessor, setAudioProcessor] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationRef = useRef(null);
  const canvasRef = useRef(null);
  const [allVideosUploaded, setAllVideosUploaded] = useState(false);
  const [detectionError, setDetectionError] = useState(false);
  const [error, setError] = useState("");
  const [webcamReference, setWebcamReference] = useState(null);
  const [webcamStream, setWebcamStream] = useState(null);
  const [windowsize, setwindowsize] = useState({
    height: window.innerHeight - 300,
    width: window.innerWidth - 100,
  });
  const videoConstraints = {
    facingMode: "user",
    borderRadius: "20px",
  };
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isRecordingReady, setIsRecordingReady] = useState(false); // Controls "Start Recording" visibility
  const [currentQuestionAudio, setCurrentQuestionAudio] = useState(null);

  const [Interview, setInterView] = useState(false);
  // console.log(localStorage);
  const userID = localStorage.getItem("userId");
  // console.log("user ID:", userID);
  // Get selected language from Redux
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  // useEffect(() => {
  //   if (questions[questionNo - 1] && questions[questionNo - 1]?.audioUrl) {
  //     setCurrentQuestionAudio(questions[questionNo - 1]?.audioUrl);
  //   }
  // }, [questions[questionNo - 1]]);
  useEffect(() => {
    if (questions.length && questionNo > 0) {
      const questionAudio =
        questions[questionNo - 1]?.audioUrls?.[selectedLanguage] || "";
      setCurrentQuestionAudio(questionAudio);
    }
  }, [questions, questionNo]);

  const token = Cookies.get("token");
  // console.log("token:", token);
  useEffect(() => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const processor = new AudioProcessor(audioContext);
    setAudioProcessor(processor);

    return () => {
      processor.dispose(); // Make sure to clean up on component unmount
    };
  }, []);

  const response = GetStudentsDetails(token);
  // console.log("response:", response);
  let userData;
  response.then((data) => {
    // console.log(data.data.data._id);
    userData = data.data.data._id; // Saving the data into the userData variable
    // console.log("UserID: ", userData);
  });
  const handleLoadedMetadata = (e) => {
    e.preventDefault();
    // console.log("loaded");
    const video = videoplayerRef.current;
    if (!video) return;
    // setQuestionDuration(video.duration * 1000);
    setQuestionDuration(video.duration);
    setVideoLoaded(false);
  };
  // console.log("Render VideoPage2 component");
  // console.log(videoLoaded);
  useEffect(() => {
    const handleWindowResize = () => {
      let { innerWidth, innerHeight } = window;
      setwindowsize({
        ...windowsize,
        height: innerHeight - 300,
        width: innerWidth - 100,
      });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  // console.log(video);
  urlsLength = videoUrl.length;
  // console.log(videoplayerRef.current);
  // console.log(urlsLength);
  // console.log(videoUrl);
  // console.log("questions", questions);
  // Function to handle the question based on its type
  const getQuestionText = (question) => {
    if (!question) {
      return "Question not available"; // Handle undefined or null question
    }
    if (typeof question === "string") {
      return question; // Return the string directly for older schema
    }
    if (typeof question === "object" && selectedLanguage in question) {
      return question[selectedLanguage]; // Return the translated text
    }
    return question["en"] || "Question not available"; // Fallback to English
  };

  const finalSubmit = async () => {
    // urlsLength= videoUrl.length

    console.log("Video URL", videoUrl);
    console.log("video", video);
    console.log("Url length", urlsLength);
    const userID = localStorage.getItem("userId");
    // const userID = userData;
    console.log("user ID:", userID);
    console.log("user ID:", userData);
    console.log("video length:", video.length);

    // if (video.length === urlsLength) {
    if (allVideosUploaded) {
      setFeedbackpage(true);
      setFeedbackLoading(true);
      mediaRecorderRef.current.stop();
      try {
        const formdata = new FormData();
        formdata.append("type", role === "student" ? "Student" : "Interviewee");
        formdata.append("interviewQuestions", JSON.stringify(questions));
        formdata.append("interviewId", params.id);
        for (var i = 0; i < video.length; i++) {
          formdata.append("video", video[i]);
        }
        const timestamp = new Date().getTime(); // Get current timestamp
        const interviewId = params.id + "_" + userID + "_" + timestamp; // Construct interview_id
        // const interviewId = generateObjectId(params.id, userID, timestamp); // Generate ObjectId-like string
        console.log("interviewID", interviewId);
        console.log("Final Data Sent to API:", {
          type: role === "student" ? "Student" : "Interviewee",
          interviewQuestions: questions,
          interviewId: params.id,
          videourls: videoUrl,
          userID: userID,
          keywords: questions.map((q) => q.keyword).flat(),
        });
        const jobDetailsResponse = await GetSingleInterview({ id: params.id });
        setquestions(jobDetailsResponse.data.interview.interviewquestions);
        const keywords = questions.map((q) => q.keyword).flat(); // Collect all keywords into a single array
        const creatorType = jobDetailsResponse.data.interview.creator_type;
        const newdata = {
          type: role === "student" ? "Student" : "Interviewee",
          interviewQuestions: JSON.stringify(questions),
          interviewId: params.id,
          // interviewId: interviewId,
          videourls: videoUrl,
          userID: userID,
          keywords: keywords,
        };
        console.log("video url", videoUrl);
        console.log("Final Data Sent to API:", newdata);

        const res = await InterviewAnalysis(newdata);

        if (role === "interviewee") {
          navigate("/interviewee/dashboard");
        } else {
          navigate("/college-student/dashboard");
        }
        // Call getAllVideos API
        const capitalizedRole = role.charAt(0).toUpperCase() + role.slice(1); // Capitalize the role
        console.log("role:", capitalizedRole);
        // const videosResponse = await getAllVideos({ id: params.id, userID: userID, type: role });
        // const url = `https://api.viosa.in/interview-pro/interview/all-videos/${params.id}?attemptedby_id=${userID}&attempt_type=${capitalizedRole}`;
        // const response = await fetch(url);
        // const videosResponse = await response.json();
        const videosResponse = await getAllVideos({
          id: params.id,
          attemptedby_id: userID,
          attempt_type: capitalizedRole,
        });
        console.log("All videos response:", videosResponse.data);
        console.log("Toast message before navigation");
        setTimeout(() => {
          toast.success(
            "Check your report in 15 mins in the interview report section."
          );
          console.log("Toast message after navigation");
        }, 3000);

        // debugger
        setFeedbackLoading(false);
        // }
      } catch (error) {
        // console.log(error);
        toast.error("Try Again", ToastStyles);
        setFeedbackpage(false);
        setFeedbackLoading(false);
      }
    } else {
      setLoadingModal(true);
      setSeconds(30);
      setTimeout(() => {
        setLoadingModal(false);
        // finalSubmit();
      }, 30000);
      return;
    }
  };
  console.log(videoUrl);

  const UploadVideo = async () => {
    if (video.length) {
      // setLoadingIconupload(true);
      console.log("video url", videoUrl);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          // setPercentage(percentange);
          if (percentange === 100) {
            // setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", video[video.length - 1]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      // setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          console.log(data.data.link);
          setVideoUrl((prevUrls) => {
            const updatedUrls = [...prevUrls, data.data.link];
            console.log(
              "Updated Video URLs:",
              updatedUrls.length,
              "Expected Count:",
              video.length
            );
            return updatedUrls;
          });
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          // setLoadingIconupload(false);
        });
    }
  };

  useEffect(
    () => {
      console.log(
        `Video URLs: ${videoUrl.length}, Video Count: ${video.length}`
      );
      // This will ensure the check is done after state updates
      if (video.length === urlsLength) {
        console.log("All videos uploaded.");
        setAllVideosUploaded(true);
        console.log("all videos", allVideosUploaded);
      } else {
        setAllVideosUploaded(false);
        console.log("all videos", allVideosUploaded);
      }
    },
    [videoUrl.length, video.length],
    1000
  );

  const blobToFile = (videoBlob, fileName, fileExtension) => {
    return new File([videoBlob], `${fileName}.${fileExtension}`, {
      lastModified: new Date().getTime(),
      type: videoBlob.type,
    });
  };
  const handleUserMedia = (stream) => {
    console.log("Stream ready:", stream);
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 64;
    source.connect(analyser);
    audioContextRef.current = audioContext;
    analyserRef.current = analyser;

    draw();
  };

  const draw = useCallback(() => {
    if (!analyserRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    console.log("Buffer Length:", bufferLength); // Debugging line

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const drawVisual = () => {
      animationRef.current = requestAnimationFrame(drawVisual);
      analyserRef.current.getByteFrequencyData(dataArray);

      // console.log("Data Array:", dataArray); // Debugging line

      ctx.clearRect(0, 0, WIDTH, HEIGHT); // Clear the previous frame
      ctx.fillStyle = "rgb(0, 0, 255)";
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      const numBars = 3;
      const barGap = WIDTH * 0.1; // Set the gap between the bars
      const barWidth = (WIDTH - numBars * barGap) / numBars;
      let barHeight;
      let x = (WIDTH - (numBars * barWidth + (numBars - 1) * barGap)) / 2;
      const maxBarHeight = HEIGHT * 0.4;
      ctx.lineCap = "round";

      for (let i = 0; i < numBars; i++) {
        barHeight = (dataArray[i] * maxBarHeight) / 256;
        let y = (HEIGHT - barHeight) / 2;

        ctx.fillStyle = "rgb(255, 255, 255)"; // White bars
        ctx.beginPath();
        ctx.rect(x, y, barWidth, barHeight); // Draw the rectangle for the bar
        ctx.fill();

        x += barWidth + barGap; // Increase x by the width of the bar and the gap
      }
    };

    drawVisual();
  }, []);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close().then(() => {
          console.log("AudioContext closed successfully.");
        });
      }
      // Ensure all media tracks are stopped
      if (webcamRef.current && webcamRef.current.stream) {
        webcamRef.current.stream.getTracks().forEach((track) => {
          track.stop();
          console.log("Track stopped on cleanup:", track.kind);
        });
      }
    };
  }, []);

  // const handleStartCaptureClick = async (e) => {
  //   e.preventDefault();
  //   setRunning(true);
  //   toast.success("Recording Started", ToastStyles);
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true, video: true })
  //     .then((stream) => {
  //       mediaRecorderRef.current = new MediaRecorder(stream, {
  //         type: 'video/mp4; codecs="avc1.424028, mp4a.40.2"',
  //         audioBitsPerSecond: 5120000,
  //         videoBitsPerSecond: 5120000,
  //         dataBitsPerSecond: 5120000,
  //       });
  //       const chunks = [];
  //       mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
  //         console.log(event);
  //         chunks.push(event.data);
  //       });
  //       mediaRecorderRef.current.addEventListener("stop", async (e) => {
  //         // console.log(e);
  //         let videoBlob = new Blob(chunks, { type: "video/webm" });
  //         const duration = Date.now() - starttime;
  //         console.log(Date.now());
  //         console.log(starttime);

  //         console.log(duration);
  //         let videoBlob2 = await webmFixDuration(videoBlob, duration);
  //         const filename = videoBlob2.size;
  //         console.log("videoblob2:");
  //         console.log(videoBlob2);
  //         let newdata = blobToFile(videoBlob2, Date.now(), "mp4");
  //         // UploadVideo(newdata)
  //         setvideo((prevVideo) => [...prevVideo, newdata]);
  //         recordedChunks.current = [];
  //         setTestvideo([...testvideo, URL.createObjectURL(videoBlob)]);
  //         chunks.length = 0;
  //       });
  //       mediaRecorderRef.current.start();
  //       setStartTime(Date.now());
  //       setCapturing(true);
  //       setBtnDisable(true);
  //       setTimeout(() => {
  //         setBtnDisable(false);
  //       }, 15000);
  //     });
  // };

  const handleStartCaptureClick = async (e) => {
    e.preventDefault();
    if (!audioProcessor) {
      console.error("Audio processor is not initialized.");
      return;
    }
    setRunning(true);
    toast.success("Recording Started", ToastStyles);
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
          autoGainControl: true,
        },
        video: true,
      })
      .then(async (stream) => {
        // Process audio stream for noise reduction
        const processedAudioStream =
          await audioProcessor.getProcessedAudioStream();
        setAudioStream(processedAudioStream);
        console.log("Processed audio stream:", processedAudioStream);

        const videoStream = webcamRef.current.stream;
        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...processedAudioStream.getAudioTracks(),
        ]);
        mediaRecorderRef.current = new MediaRecorder(combinedStream);
        console.log("stream", stream);
        const chunks = [];
        mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
          chunks.push(event.data);
        });

        mediaRecorderRef.current.addEventListener("stop", async (e) => {
          console.log(e);
          let videoBlob = new Blob(chunks, { type: "video/webm" });
          const duration = Date.now() - starttime;
          console.log(Date.now());
          console.log(starttime);

          console.log(duration);
          let videoBlob2 = await webmFixDuration(videoBlob, duration);
          const filename = videoBlob2.size;
          console.log("videoblob2:");
          console.log(videoBlob2);
          let newdata = blobToFile(videoBlob2, Date.now(), "mp4");
          // UploadVideo(newdata)
          setvideo((prevVideo) => [...prevVideo, newdata]);
          recordedChunks.current = [];
          setTestvideo([...testvideo, URL.createObjectURL(videoBlob)]);
          chunks.length = 0;
        });
        mediaRecorderRef.current.start();
        setStartTime(Date.now());
        setCapturing(true);
        setBtnDisable(true);
        setTimeout(() => setBtnDisable(false), 15000);
      });
  };

  // const handleStopCaptureClick = () => {
  //   // e.preventDefault();
  //   const duration = Date.now() - starttime;
  //   // console.group(Date.now());
  //   mediaRecorderRef.current.stop();
  //   mediaRecorderRef.current.ondataavailable = (e) => {
  //     const videoURL = URL.createObjectURL(e.data);
  //     setvideo(videoURL);
  //     console.log("Video URL:", videoURL);
  //   };
  //   console.log("Recording stopped.");
  //   // Stop all tracks on the media stream
  //   if (webcamRef.current && webcamRef.current.stream) {
  //     webcamRef.current.stream.getTracks().forEach((track) => {
  //       track.stop();
  //       console.log("Track stopped:", track.kind);
  //     });
  //   }
  //   // Stop all tracks and dispose of audio processing resources
  //   if (audioProcessor) {
  //     audioProcessor.dispose();
  //   }

  //   if (capturing !== true) {
  //     // toast.error("Start the Recording First", ToastStyles);
  //   } else if (duration < 15000) {
  //     toast.error("Video must be greater than 15 seconds", ToastStyles);
  //   } else {
  //     setRunning(false);
  //     setvideoReplay(false);
  //     mediaRecorderRef.current?.stop();
  //     setCapturing(false);
  //     setRecordvideo(true);
  //   }
  // };
  const handleStopCaptureClick = (e) => {
    // e.preventDefault();
    // Ensure that the event object is available before using it
    e?.preventDefault();
    const duration = Date.now() - starttime;
    // console.group(Date.now());
    // console.log(duration);
    // console.log("Video Duration:", questions[questionNo - 1]?.duration);
    mediaRecorderRef.current.stop();
    // mediaRecorderRef.current.ondataavailable = (e) => {
    //   const videoURL = URL.createObjectURL(e.data);
    //   setvideo(videoURL);
    //   console.log("Video URL:", videoURL);
    // };
    if (!capturing) {
      console.log("Recording was not active, no need to stop.");
      return; // Add a return statement to exit if not currently capturing
    }
    console.log("Recording stopped.");
    // Stop all tracks on the media stream
    // if (webcamRef.current && webcamRef.current.stream) {
    //   webcamRef.current.stream.getTracks().forEach((track) => {
    //     track.stop();
    //     console.log("Track stopped:", track.kind);
    //   });
    // }
    // Stop MediaRecorder but not the stream itself
    // if (
    //   mediaRecorderRef.current &&
    //   mediaRecorderRef.current.state !== "inactive"
    // ) {
    //   mediaRecorderRef.current.stop();
    // }
    // Stop all tracks and dispose of audio processing resources
    if (audioProcessor) {
      audioProcessor.dispose();
    }

    if (capturing !== true) {
      // toast.error("Start the Recording First", ToastStyles);
    } else if (duration < 15000) {
      toast.error("Video must be greater than 15 seconds", ToastStyles);
    } else {
      setvideoReplay(false);
      setRunning(false);
      mediaRecorderRef.current?.stop();
      setCapturing(false);
      setRecordvideo(true);
    }
  };

  const recordagainstart = () => {
    setQuestionNo(1);
    setvideo([]);
    setVideoUrl([]);
    setTestvideo([]);
    setSubmitpage(false);
    setRecordedAnsNo(1);
    handleClose();
  };
  const RecordThisAgain = () => {
    setRecordvideo(false);
    let newvideoes = video;
    let newtestvideoes = testvideo;
    newvideoes.pop();
    newtestvideoes.pop();
    setvideo(newvideoes);
    setTestvideo(newtestvideoes);
    handleClose();
  };
  const date = new Date(null);
  const date2 = new Date(null);
  date2.setSeconds(questions[questionNo - 1]?.duration);
  date.setSeconds(time);
  // console.log(time)
  useEffect(() => {
    if (time >= questions[questionNo - 1]?.duration) {
      toast.error("Timeup", ToastStyles);
      handleStopCaptureClick();
    }
  }, [time]);

  // useEffect(() => {
  //   const userID = localStorage.getItem("userId");
  //   console.log("user ID:", userID);
  //   let interval;
  //   if (running) {
  //     date.setSeconds(0);
  //     interval = setInterval(() => {
  //       setTime((prevTime) => prevTime + 1);
  //       // Check if the timer has reached zero
  //       if (minutes === 0 && seconds === 0) {
  //         // Call the function to stop recording
  //         handleStopCaptureClick();
  //       }
  //     }, 1000);
  //   } else if (!running) {
  //     clearInterval(interval);
  //     setTime(0);
  //   }
  //   return () => clearInterval(interval);
  // }, [running]);
  useEffect(() => {
    const userID = localStorage.getItem("userId");
    console.log("user ID:", userID);
    let interval;
    if (running) {
      date.setSeconds(0);
      interval = setInterval(() => {
        setTime((prevTime) => {
          // Update time and check conditions within the same update to avoid race conditions
          const newTime = prevTime + 1;
          if (newTime >= questions[questionNo - 1]?.duration) {
            clearInterval(interval);
            if (capturing) {
              handleStopCaptureClick();
            }
          }
          return newTime;
        });
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
      setTime(0);
    }

    return () => clearInterval(interval);
  }, [running]);

  // console.log()
  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      // Normalize questions here
      const normalizedQuestions =
        response.data.interview.interviewquestions.map((q) => {
          const questionText =
            typeof q.question === "string"
              ? q.question // Old schema
              : q.question[selectedLanguage] ||
                q.question.en ||
                "Question not available"; // New schema
          return { ...q, question: questionText }; // Replace `question` with normalized string
        });
      setquestions(normalizedQuestions);
      // setquestions(response.data.interview.interviewquestions);
      setInterView(response.data.interview);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetInterviewDetails();
  }, [params.id, selectedLanguage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // setResendActive(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleWebcamRef = (ref) => {
    webcamRef.current = ref.current;
  };

  const handleStreamReady = (stream) => {
    setWebcamStream(stream);
  };

  // const selector = useCallback(voices => [...voices].find(v => v.lang === 'hi-IN'), [])
  // const [playingQuestion, setplayingQuestion] = useState(false);

  const handleAudioEnd = () => {
    // This function will be called when the audio ends
    const duration =
      /* Calculate or estimate audio duration */
      // setAudioDuration(duration);

      // Set a timeout to stop the video after the audio duration
      setTimeout(() => {
        if (videoplayerRef.current) {
          videoplayerRef.current.seekTo(0); // Reset video to start
          // You might want to pause or stop the video here
          // Example: videoRef.current.pause();
        }
      }, duration * 1000); // Convert duration to milliseconds
  };
  const handlePlayQuestion = () => {
    setIsAudioPlaying(true); // Start audio and visualizer
    setIsRecordingReady(false); // Reset recording readiness
    setBtnDisable(true); // Disable "Play Question" button during playback
  };

  const handleAudioEnded = () => {
    setIsAudioPlaying(false); // Deactivate visualizer
    setBtnDisable(false); // Enable the button when the video ends
    setvideoReplay(true);
  };
  // Add a new Modal for the thank you message
  const ThankYouModal = () => (
    <Modal show={ThankyouModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Thank You</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">You can close this window.</h4>
      </Modal.Body>
    </Modal>
  );
  const isCorporate = Interview?.creator_type === "corporate";

  return (
    <>
      <Modal show={ThankyouModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Thank You</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-left">You can close this window.</h4>
        </Modal.Body>
      </Modal>
      <Modal
        show={loadingModal}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          Please wait while we're submmiting your Response.
          <h4 className="text-center my-4">00 : {seconds}</h4>
        </Modal.Body>
      </Modal>
      <Toaster />
      <Header />
      {loading ? (
        <>
          <InterviewBoxStyled className=" interview-form d-flex scroller h-70 padding-0 align-items-center justify-content-center">
            <Loading />
          </InterviewBoxStyled>
        </>
      ) : (
        <>
          <InterviewBoxStyled className="scroller interview-form h-70 padding-0 ">
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5> Are you sure you want to record again?</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h6>
                  {" "}
                  Do take note that this will require you to re-do the entire
                  interview from question 1. Press the button Record Again to
                  restart, or you can close this window and proceed to submit
                  your responses.
                </h6>
              </Modal.Body>
              <Modal.Footer>
                <ButtonYellowOutline
                  variant="secondary"
                  onClick={recordagainstart}
                >
                  Record Again
                </ButtonYellowOutline>
                <ButtonYellowFill width="120px" onClick={handleClose}>
                  Close
                </ButtonYellowFill>
              </Modal.Footer>
            </Modal>
            {!feedbackpage && (
              <>
                {!submitpage && (
                  <InterviewPageBox className=" page-box px-5">
                    <div className="col-12">
                      {recordvideo ? (
                        <>
                          <div className="section-1 my-3 w-100 d-flex flex-column px-3">
                            <div className="d-flex justify-content-center mt-3">
                              <div className="col-md-6">
                                <div className="px-3 d-flex justify-content-center flex-column">
                                  <h6 className="text-center">Hey! {name}</h6>
                                  <h6 className="text-center">
                                    Check Your Video
                                  </h6>
                                  <div className=" video-player pb-4">
                                    <ReactPlayer
                                      url={testvideo[testvideo?.length - 1]}
                                      controls={true}
                                      ref={videoQuestion}
                                      // muted
                                      width="100%"
                                      height="100%"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center my-3">
                                    {!isCorporate && (
                                      <ButtonYellowOutline
                                        onClick={RecordThisAgain}
                                        style={{ width: "350px" }}
                                      >
                                        Record Again
                                      </ButtonYellowOutline>
                                    )}
                                  </div>
                                  <div className="d-flex justify-content-center my-3">
                                    <ButtonYellowFill
                                      onClick={() => {
                                        console.log(
                                          "Okay, Let's Proceed button clicked"
                                        );
                                        setVideoLoaded(true);
                                        setRecordvideo(false);
                                        if (questionNo === questions.length) {
                                          UploadVideo();
                                          setSubmitpage(true);
                                          // window.location.reload()
                                        } else {
                                          console.log(
                                            "Proceeding to the next question"
                                          );
                                          setQuestionNo((prev) => prev + 1);
                                          UploadVideo();
                                        }
                                      }}
                                      width="350px"
                                    >
                                      Okay, Let's Proceed
                                    </ButtonYellowFill>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="section-1 video-page-2  w-100 d-flex flex-column px-3">
                            <div className="d-flex ">
                              <h5 className="my-2 text-center lh-20">
                                Q.{questionNo}&nbsp;/&nbsp;Q.{questions.length}
                                &nbsp;
                                {questions.length > 0 &&
                                  questions[questionNo - 1]?.question}
                              </h5>
                            </div>
                            <div className="d-flex video-box gap-1 mt-1">
                              <div className="d-flex col-6 video-res justify-content-center flex-column">
                                <div className="video-player video-cam-box">
                                  {/* <video
                                      controls={false}
                                      className="player m-auto d-block"
                                      style={{
                                        height: windowsize.height,

                                        width: windowsize.height,
                                        objectFit: "fill",
                                      }}
                                      ref={videoplayerRef}
                                      onLoadedMetadata={handleLoadedMetadata}
                                      preload="auto" // Add preload attribute
                                      loading="lazy" // Add lazy loading attribute
                                      src={
                                        questions[questionNo - 1]
                                          ?.videoQuestion ||
                                        "https://viosa-storage-2.s3.amazonaws.com/Sequence%2001_1.mp4"
                                      }
                                      onEnded={() => {
                                        setBtnDisable(false); // Enable the button when the video ends
                                        setvideoReplay(true);
                                      }}
                                    ></video>{" "} */}
                                  <AudioBlobVisualizer
                                    playAudio={isAudioPlaying} // Pass the playAudio state
                                    questionText={
                                      questions[questionNo - 1]?.question
                                    }
                                    onAudioEnded={handleAudioEnded}
                                    audioUrl={currentQuestionAudio}
                                  />
                                </div>
                              </div>

                              <div className="col-6 video-res">
                                <div className="">
                                  <div className="mx-auto video-cam-box width-set">
                                    {" "}
                                    <div className="Container">
                                      <div className="d-flex justify-content-center main-video">
                                        {/* <Webcam
                                          style={{ objectFit: "cover" }}
                                          width={windowsize.width / 2}
                                          height={windowsize.height}
                                          audio={true}
                                          mirrored={true}
                                          muted={true}
                                          ref={webcamRef}
                                          videoConstraints={videoConstraints}
                                          onUserMedia={handleUserMedia}
                                        />
                                        <canvas
                                          ref={canvasRef}
                                          style={canvasStyles}
                                        ></canvas> */}
                                        <WebcamWithDetection
                                          setDetectionError={setDetectionError}
                                          audioContextRef={audioContextRef}
                                          analyserRef={analyserRef}
                                          canvasRef={canvasRef}
                                          onWebcamRef={handleWebcamRef}
                                          onStreamReady={handleStreamReady}
                                          setError={setError}
                                        />
                                      </div>
                                      {detectionError && (
                                        <p
                                          style={{
                                            color: "red",
                                            padding: "10px",
                                          }}
                                        >
                                          {error}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end content-center">
                              <div className="d-flex justify-content-center  flex-column">
                                <h6 className="purple text-center mb-0 lh-20">
                                  Answer for minimum 15 seconds
                                </h6>
                                <h6 className="yellow text-center mb-0 lh-20">
                                  <span
                                    className={
                                      time - 5 >
                                      questions[questionNo - 1]?.duration
                                        ? "color-red"
                                        : ""
                                    }
                                  >
                                    {date.toISOString().substr(11, 8)}
                                  </span>
                                  /
                                  {questions.length > 0 &&
                                    date2.toISOString().substr(11, 8)}
                                </h6>
                              </div>
                            </div>
                            <div className="d-flex justify-content-around button-box my-2">
                              <form>
                                {videoreplay ? (
                                  <>
                                    {capturing ? (
                                      <ButtonYellowFill
                                        data-toggle={`${
                                          btnDisable ? "tooltip" : "none"
                                        }`}
                                        data-placement="bottom"
                                        title={`${
                                          btnDisable
                                            ? "Video must be greater than 15 seconds"
                                            : ""
                                        }`}
                                        width="150px"
                                        disabled={btnDisable}
                                        onClick={handleStopCaptureClick}
                                        className="h-40 bg-red"
                                      >
                                        Stop Recording
                                      </ButtonYellowFill>
                                    ) : (
                                      <ButtonYellowFill
                                        onClick={handleStartCaptureClick}
                                        width="150px"
                                        className="h-40 bg-green"
                                      >
                                        Start Recording
                                      </ButtonYellowFill>
                                    )}
                                  </>
                                ) : (
                                  <ButtonYellowFill
                                    width="150px"
                                    disabled={btnDisable}
                                    className="h-40 bg-green"
                                    // onClick={() => {
                                    //   setIsAudioPlaying(true); // Start playing audio and visualizer
                                    //   setBtnDisable(true); // Disable the button immediately
                                    //   setIsRecordingReady(false); // Reset recording readiness
                                    //   // videoplayerRef.current.play(); // Start video playback
                                    // }}
                                    onClick={handlePlayQuestion}
                                  >
                                    {btnDisable
                                      ? "Playing..."
                                      : "Play Question"}
                                  </ButtonYellowFill>
                                )}
                              </form>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </InterviewPageBox>
                )}
                {submitpage && (
                  <FinalReviewAns
                    handleShow={handleShow}
                    testvideo={testvideo}
                    video={video}
                    questions={questions}
                    setRecordedAnsNo={setRecordedAnsNo}
                    recordedAnsno={recordedAnsno}
                    finalSubmit={finalSubmit}
                    allVideosUploaded={allVideosUploaded}
                  />
                )}
                <ThankYouModal />
              </>
            )}
            {feedbackpage && (
              <>
                <Feedback feedbackLoading={feedbackLoading} />
              </>
            )}
            <Footer />
          </InterviewBoxStyled>
        </>
      )}
    </>
  );
};
