// AudioProcessor.js
export class AudioProcessor {
  constructor(audioContext) {
    this.audioContext =
      audioContext || new (window.AudioContext || window.webkitAudioContext)();
  }

  async getProcessedAudioStream() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      console.log("Media stream created.");

      if (!this.audioContext) {
        console.log("AudioContext not initialized or closed");
        return;
      }

      this.source = this.audioContext.createMediaStreamSource(stream);
      this.processor = this.audioContext.createScriptProcessor(4096, 1, 1);
      if (!this.processor) {
        console.error("ScriptProcessorNode was not created successfully.");
        return;
      }
      // Simple noise gate
      this.processor.onaudioprocess = (e) => {
        var input = e.inputBuffer.getChannelData(0);
        var output = e.outputBuffer.getChannelData(0);
        for (var i = 0; i < input.length; i++) {
          output[i] = Math.abs(input[i]) > 0.02 ? input[i] : 0;
        }
      };

      this.source.connect(this.processor);
      this.processor.connect(this.audioContext.destination);

      const destination = this.audioContext.createMediaStreamDestination();
      this.processor.connect(destination);

      return new MediaStream([destination.stream.getAudioTracks()[0]]);
    } catch (error) {
      console.error("Error accessing media devices.", error);
      throw error;
    }
  }
  dispose() {
    if (this.processor) {
      this.processor.disconnect();
      this.processor.onaudioprocess = null;
      this.processor = null;
    }
    if (this.source) {
      this.source.disconnect();
      this.source = null;
    }
    // if (this.audioContext) {
    //   // this.audioContext.close(); // Consider whether you might need the context again
    //   this.audioContext = null;
    // }
  }
}
