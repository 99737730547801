import React from "react";
import "./Report.css";
import CardTop from "assets/image/Rectangle 3.png";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
const ReportCard = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  // console.log("props", props);
  const data = {
    labels: [
      `Extraversion-Introversion (${(
        (props.data.body["Sociability_Video"] +
          props.data.body["Energy_Voice"]) /
        2
      ).toFixed(0)}%)`,
      `Openness (${(
        (props.data.body["Energy_Level_Video"] +
          props.data.body["Energy_Voice"] +
          props.data.body["Positive_Emotion_Facial_Expression"] +
          props.data.body["Communication_Video"] +
          props.data.body["Clarity_Text"]) /
        5
      ).toFixed(0)}%)                       `,
      `Agreeableness (${(
        (props.data.body["Positive_Emotion_Facial_Expression"] +
          props.data.body["Clarity_Text"]) /
        2
      ).toFixed(0)}%)               `,
      `Neuroticism (${(
        (props.data.body["Sentiment_Analysis_Text"] +
          props.data.body["Energy_Voice"]) /
        2
      ).toFixed(0)}%)                    `,
      `Conscientiousness (${(
        (props.data.body["Pace_Text"] +
          props.data.body["Clarity_Text"] +
          props.data.body["Sentiment_Analysis_Text"]) /
        3
      ).toFixed(0)}%)         `,
    ],
    datasets: [
      {
        data: [
          (props.data.body["Sociability_Video"] +
            props.data.body["Energy_Voice"]) /
            2,
          (props.data.body["Energy_Level_Video"] +
            props.data.body["Energy_Voice"] +
            props.data.body["Positive_Emotion_Facial_Expression"] +
            props.data.body["Communication_Video"] +
            props.data.body["Clarity_Text"]) /
            5,
          (props.data.body["Positive_Emotion_Facial_Expression"] +
            props.data.body["Clarity_Text"]) /
            2,
          (props.data.body["Sentiment_Analysis_Text"] +
            props.data.body["Energy_Voice"]) /
            2,
          (props.data.body["Pace_Text"] +
            props.data.body["Clarity_Text"] +
            props.data.body["Sentiment_Analysis_Text"]) /
            3,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          // 'rgba(255, 159, 64, 0.5)',
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          // 'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            style={{
              height: "10px",
              borderRadius: "5px",
              background: "#f4f4f4",
            }}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <div className="res-box col-12  mb-0">
        {/* <img src={CardTop} style={{
          width: "100%"
        }} className="res-top-img" alt="topborder" /> */}
        <div className="res-bottom"></div>
        <div className=" px-3 pb-3">
          <div className="card-head d-flex justify-content-center">
            <h6>Video Interview Assesment</h6>
          </div>
          <div className="que-des my-2">
            Question No {props?.index + 1 || 1}:-{" "}
            {props?.data?.question || "Question"}
          </div>
          <div className="d-flex gap-2 ">
            <div className="col-12 report-box p-2">
              <h6>Professionalism</h6>
              <ul class="skill-list">
                <li class="skill">
                  <h3>
                    Confidence (
                    {(
                      (props?.data.body["Energy_Voice"] +
                        props?.data.body["Energy_Level_Video"] +
                        props?.data.body["Sentiment_Analysis_Text"] +
                        props?.data.body["Clarity_Text"] +
                        props?.data.body["Communication_Video"]) /
                      5
                    ).toFixed(0)}
                    %)
                  </h3>
                  {/* <progress
                    class="skill-1"
                    max="100"
                    value={
                      (props?.data.body["Energy_Voice"] +
                        props?.data.body["Energy_Level_Video"] +
                        props?.data.body["Sentiment_Analysis_Text"] +
                        props?.data.body["Clarity_Text"] +
                        props?.data.body["Communication_Video"]) /
                      5
                    }
                  >
                    <strong>Skill Level: 50%</strong>
                  </progress> */}
                  <div
                    className="progress-container"
                    style={{
                      width: "100%",
                      backgroundColor: "#eee",
                      height: "20px",
                      borderRadius: "20px",
                      border: "1px solid #6d6d6d",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${
                          (props?.data.body["Energy_Voice"] +
                            props?.data.body["Energy_Level_Video"] +
                            props?.data.body["Sentiment_Analysis_Text"] +
                            props?.data.body["Clarity_Text"] +
                            props?.data.body["Communication_Video"]) /
                          5
                        }%`,
                        height: "100%",
                        backgroundColor: "#0d6efd",
                        textAlign: "center",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px 0px 0px 20px",
                      }}
                    ></div>
                  </div>
                </li>

                {/* <li class="skill">
                  <h3>Energy Level ({((props?.data.body["Energy_Voice"] + props?.data.body["Energy_Level_Video"]) / 2).toFixed(0)}%)</h3>
                  <progress class="skill-2" max="100" value={(props?.data.body["Energy_Voice"] + props?.data.body["Energy_Level_Video"]) / 2}>
                    <strong>Skill Level: 75%</strong>
                  </progress>
                </li> */}

                <li class="skill">
                  <h3>
                    Optimistic (
                    {props?.data.body[
                      "Positive_Emotion_Facial_Expression"
                    ]?.toFixed(0)}
                    %)
                  </h3>
                  {/* <progress
                    class="skill-3"
                    max="100"
                    value={
                      props?.data?.body["Positive_Emotion_Facial_Expression"]
                    }
                  >
                    <strong>Skill Level: 25%</strong>
                  </progress> */}
                  <div
                    className="progress-container"
                    style={{
                      width: "100%",
                      backgroundColor: "#eee",
                      height: "20px",
                      borderRadius: "20px",
                      border: "1px solid #6d6d6d",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${props?.data?.body["Positive_Emotion_Facial_Expression"]}%`,
                        height: "100%",
                        backgroundColor: "#0d6efd",
                        textAlign: "center",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px 0px 0px 20px",
                      }}
                    ></div>
                  </div>
                </li>

                <li class="skill">
                  <h3>
                    Passion & Enthusiasm (
                    {(
                      (props?.data.body["Energy_Voice"] +
                        props?.data.body["Pace_Text"] +
                        props?.data?.body["Clarity_Text"]) /
                      3
                    )?.toFixed(0)}
                    %)
                  </h3>
                  {/* <progress
                    class="skill-1"
                    max="100"
                    value={
                      (props?.data.body["Energy_Voice"] +
                        props?.data?.body["Pace_Text"] +
                        props?.data?.body["Clarity_Text"]) /
                      3
                    }
                  >
                    <strong>Skill Level: 50%</strong>
                  </progress> */}
                  <div
                    className="progress-container"
                    style={{
                      width: "100%",
                      backgroundColor: "#eee",
                      height: "20px",
                      borderRadius: "20px",
                      border: "1px solid #6d6d6d",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${
                          (props?.data.body["Energy_Voice"] +
                            props?.data?.body["Pace_Text"] +
                            props?.data?.body["Clarity_Text"]) /
                          3
                        }%`,
                        height: "100%",
                        backgroundColor: "#0d6efd",
                        textAlign: "center",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px 0px 0px 20px",
                      }}
                    ></div>
                  </div>
                </li>

                <li class="skill">
                  <h3>
                    Industry Keywords (
                    {props?.data?.body["Professionalism_Video"]?.toFixed(0)}%)
                  </h3>
                  {/* <progress
                    class="skill-2"
                    max="100"
                    value={props?.data?.body["Professionalism_Video"]}
                  >
                    <strong>Skill Level: 75%</strong>
                  </progress> */}
                  <div
                    className="progress-container"
                    style={{
                      width: "100%",
                      backgroundColor: "#eee",
                      height: "20px",
                      borderRadius: "20px",
                      border: "1px solid #6d6d6d",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${props?.data?.body["Professionalism_Video"]}%`,
                        height: "100%",
                        backgroundColor: "#0d6efd",
                        textAlign: "center",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px 0px 0px 20px",
                      }}
                    ></div>
                  </div>
                </li>

                {/* <li class="skill">
                  <h3>Grooming (25%)</h3>
                  <progress class="skill-3" max="100" value="25">
                    <strong>Skill Level: 25%</strong>
                  </progress>
                </li> */}

                <li class="skill">
                  <h3>
                    Clarity ({props?.data?.body["Clarity_Text"]?.toFixed(0)}%)
                  </h3>
                  {/* <progress
                    class="skill-1"
                    max="100"
                    value={props?.data?.body["Clarity_Text"]}
                  >
                    <strong>Skill Level: 25%</strong>
                  </progress> */}
                  <div
                    className="progress-container"
                    style={{
                      width: "100%",
                      backgroundColor: "#eee",
                      height: "20px",
                      borderRadius: "20px",
                      border: "1px solid #6d6d6d",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${props?.data?.body["Clarity_Text"]}%`,
                        height: "100%",
                        backgroundColor: "#0d6efd",
                        textAlign: "center",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px 0px 0px 20px",
                      }}
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="col-5 report-box">
              <div className="p-2">
                <p>Statistics</p>
                <h6>Personality</h6>
                <div className='w-75'>

                  <Doughnut data={data} />
                </div>
              </div>
            </div> */}
          </div>
          <div className="res-detail">
            <div>
              <h6>Communication</h6>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Fluency </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={props.data.body["Communication_Video"]}
                  />
                </Box>
              </div>
            </div>
            {/* <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Speed  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value={props.data.body["Energy_Voice"]} />
                </Box>
              </div>
            </div> */}
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Articulation </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={
                      (props.data.body["Communication_Video"] +
                        props.data.body["Clarity_Text"]) /
                      2
                    }
                  />
                </Box>
              </div>
            </div>
            <div>
              <h6>Video</h6>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Sociability </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={props.data.body["Sociability_Video"]}
                  />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Facial Expression </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={
                      props.data.body["Positive_Emotion_Facial_Expression"]
                    }
                  />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Energy Level </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={props.data.body["Energy_Level_Video"]}
                  />
                </Box>
              </div>
            </div>
            <div>
              <h6>Speech</h6>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Pace </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={props.data.body["Pace_Text"]}
                  />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Clarity </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={props.data.body["Clarity_Text"]}
                  />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Sentiments </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={props?.data?.body["Sentiment_Analysis_Text"]}
                  />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="col-3 mb-0">Relevance </p>
              <div className="col-9">
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={
                      props?.data?.body["Keyword_Relevance_Score"]
                        ? props?.data?.body["Keyword_Relevance_Score"]
                        : 35
                    }
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div className="report-card-tips px-3">
          {props?.question?.suggestedAnswer && (
            <p>
              <span className="font-weight-bold">Suggested Answer :</span>{" "}
              {props?.question?.suggestedAnswer}{" "}
            </p>
          )}
          {props?.question?.keyword && (
            <p>
              <span className="font-weight-bold">Keyword :</span>{" "}
              {props?.question?.keyword}{" "}
            </p>
          )}
        </div>
        <div className="res-bottom"></div>
      </div>
    </>
  );
};

export default ReportCard;
