// eslint-disable-next-line
import React, { useRef, useCallback, useEffect, useState } from "react";
import Webcam from "react-webcam";
import * as bodyPix from "@tensorflow-models/body-pix";
import "@tensorflow/tfjs-backend-webgl"; // Import the WebGL backend
import * as tf from "@tensorflow/tfjs";
const WebcamWithDetection = ({
  setDetectionError,
  audioContextRef,
  analyserRef,
  canvasRef,
  onWebcamRef, // New prop to pass webcamRef back to parent
  onStreamReady, // New prop to pass stream back to parent
  setError,
}) => {
  const webcamRef = useRef(null);
  // const [error, setError] = useState("");
  const animationRef = useRef(null);
  const [isWebcamReady, setIsWebcamReady] = useState(false);

  const [windowsize, setwindowsize] = useState({
    height: window.innerHeight - 300,
    width: window.innerWidth - 100,
  });
  const videoConstraints = {
    facingMode: "user",
    borderRadius: "20px",
  };
  const canvasStyles = {
    width: "3vw", // Full width of the container to align it properly
    height: "4vh", // Fixed height for visual consistency
    marginTop: "10px", // Space between the webcam and the visualizer
    borderRadius: "50%", // Optional: rounded corners for the visualizer
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Slightly transparent background
    position: "relative", // Position over the video
    left: "-7%",
    transform: "translateX(-50%)", // Center horizontally
    pointerEvents: "none", // Make sure it doesn't interfere with video interaction
  };

  const handleUserMedia = (stream) => {
    if (!stream) {
      console.error("Webcam stream not available.");
      return;
    }
    // Pass stream to parent component
    onStreamReady(stream);
    console.log("Stream ready:", stream);
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 64;
    source.connect(analyser);
    audioContextRef.current = audioContext;
    analyserRef.current = analyser;
    setIsWebcamReady(true);

    draw();
  };

  const draw = useCallback(() => {
    if (!analyserRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    console.log("Buffer Length:", bufferLength); // Debugging line

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const drawVisual = () => {
      animationRef.current = requestAnimationFrame(drawVisual);
      analyserRef.current.getByteFrequencyData(dataArray);

      // console.log("Data Array:", dataArray); // Debugging line

      ctx.clearRect(0, 0, WIDTH, HEIGHT); // Clear the previous frame
      ctx.fillStyle = "rgb(0, 0, 255)";
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      const numBars = 3;
      const barGap = WIDTH * 0.1; // Set the gap between the bars
      const barWidth = (WIDTH - numBars * barGap) / numBars;
      let barHeight;
      let x = (WIDTH - (numBars * barWidth + (numBars - 1) * barGap)) / 2;
      const maxBarHeight = HEIGHT * 0.4;
      ctx.lineCap = "round";

      for (let i = 0; i < numBars; i++) {
        barHeight = (dataArray[i] * maxBarHeight) / 256;
        let y = (HEIGHT - barHeight) / 2;

        ctx.fillStyle = "rgb(255, 255, 255)"; // White bars
        ctx.beginPath();
        ctx.rect(x, y, barWidth, barHeight); // Draw the rectangle for the bar
        ctx.fill();

        x += barWidth + barGap; // Increase x by the width of the bar and the gap
      }
    };

    drawVisual();
  }, []);

  useEffect(() => {
    async function loadAndPredict() {
      await tf.setBackend("webgl"); // Set the TensorFlow.js backend to WebGL
      await tf.ready(); // When resolved, TensorFlow.js is ready to use the selected backend
      console.log("Registered backends:", tf.getBackend());

      const net = await bodyPix.load(); // Loads the BodyPix model
      const detect = async () => {
        if (webcamRef.current && webcamRef.current.video.readyState === 4) {
          const video = webcamRef.current.video;
          const person = await net.segmentPerson(video, {
            internalResolution: "high", // Adjust for performance
            segmentationThreshold: 0.8, // Adjust for accuracy
          });
          // const peopleCount = person.allPoses.length;
          const peopleCount = person.allPoses.filter(
            (pose) => pose.score > 0.1 // Minimum confidence threshold
          ).length;
          // console.log("peoplecount", peopleCount);

          if (peopleCount > 1) {
            setError(
              "Oops! It looks like there's more than one person in the frame. Please ensure only you are in the video."
            );
            setDetectionError(true);
          } else if (peopleCount === 0) {
            setError(
              "Hmm, we can't detect anyone. Please adjust your position and ensure you're visible in the camera."
            );
            setDetectionError(true);
          } else {
            setError("");
            setDetectionError(false);
          }
        }
      };

      const intervalId = setInterval(detect, 1000); // Set detection interval
      return () => clearInterval(intervalId); // Clean up interval on component unmount
    }
    loadAndPredict();
  }, [setDetectionError]);

  // Pass webcamRef to parent component when it's ready
  useEffect(() => {
    if (webcamRef.current) {
      onWebcamRef(webcamRef);
    }
  }, [webcamRef.current]);

  useEffect(() => {
    return () => {
      console.log("Component unmounting...");
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        console.log("Animation frame canceled.");
      }
      if (audioContextRef.current) {
        if (audioContextRef.current.state !== "closed") {
          audioContextRef.current
            .close()
            .then(() => {
              console.log("AudioContext closed successfully during unmount.");
            })
            .catch((error) => {
              console.error(
                "Error closing AudioContext during unmount:",
                error
              );
            });
        } else {
          console.warn("AudioContext was already closed during unmount.");
        }
      }

      // Ensure all media tracks are stopped
      if (webcamRef.current && webcamRef.current.stream) {
        webcamRef.current.stream.getTracks().forEach((track) => {
          track.stop();
          console.log("Track stopped on cleanup:", track.kind);
        });
      }
    };
  }, []);
  return (
    <>
      <Webcam
        ref={webcamRef}
        style={{ objectFit: "cover" }}
        width={windowsize.width / 2}
        height={windowsize.height}
        audio={true}
        mirrored={true}
        muted={true}
        videoConstraints={videoConstraints}
        onUserMedia={handleUserMedia}
      />
      <canvas ref={canvasRef} style={canvasStyles}></canvas>
    </>
  );
};

export default WebcamWithDetection;
