import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "../redux/slices/languageSlice";

const store = configureStore({
  reducer: {
    language: languageReducer, // Add the language reducer
  },
});

export default store;
