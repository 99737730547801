// import React, { Suspense, useState, useRef, useEffect } from "react";
// import { Canvas } from "@react-three/fiber";
// import { PerspectiveCamera, PositionalAudio } from "@react-three/drei";
// import {
//   EffectComposer,
//   Bloom,
//   ToneMapping,
// } from "@react-three/postprocessing";
// import {
//   PositionalAudio as ThreePositionalAudio,
//   AudioListener,
//   CineonToneMapping,
// } from "three";
// import AudioBlob from "./AudioBlob";

// const AudioBlobVisualizer = ({
//   className = "",
//   questionText,
//   onAudioEnded,
//   playAudio,
// }) => {
//   const [play, setPlay] = useState(false);
//   const [audioUrl, setAudioUrl] = useState(null);

//   const [audioListener, setAudioListener] = useState(null);
//   const [isAudioContextInitialized, setIsAudioContextInitialized] =
//     useState(false);
//   const soundRef = useRef(null); // Replacing string refs
//   const audioListenerRef = useRef(null); // Replacing string refs

//   // Generate speech audio from the questionText
//   useEffect(() => {
//     if (!playAudio || !questionText) return;

//     window.speechSynthesis.cancel(); // Cancel any ongoing speech

//     const utterance = new SpeechSynthesisUtterance(questionText);
//     utterance.lang = "en-US";
//     utterance.rate = 1;
//     utterance.pitch = 1;

//     utterance.onstart = () => {
//       setPlay(true); // Start visualizer
//       console.log("Audio started");
//     };

//     utterance.onend = () => {
//       console.log("Audio ended");
//       setPlay(false); // Stop visualizer
//       if (onAudioEnded) onAudioEnded(); // Notify parent
//     };

//     window.speechSynthesis.speak(utterance);

//     return () => {
//       window.speechSynthesis.cancel(); // Clean up speech synthesis on unmount
//     };
//   }, [questionText, playAudio, onAudioEnded]);
//   useEffect(() => {
//     if (!soundRef.current) {
//       console.error("Sound reference is not initialized.");
//     } else {
//       console.log("Sound reference initialized:", soundRef.current);
//     }
//   }, [soundRef]);

//   return (
//     <div
//       className={className}
//       style={{ width: "100%", height: "300px", overflow: "hidden" }}
//     >
//       {/* <Canvas>
//         <PerspectiveCamera makeDefault far={100} position={[0, 0, 10]} />
//         <ambientLight intensity={0.5} />
//         <Particles sound={soundRef} play={play} />
//       </Canvas> */}
//       <Canvas style={{ width: "100%", height: "100%" }}>
//         <PerspectiveCamera makeDefault far={100} position={[0, 0, 7.1]}>
//           {audioListenerRef.current && (
//             <primitive object={audioListenerRef.current} />
//           )}
//         </PerspectiveCamera>

//         <Suspense fallback={null}>
//           {/* {audioUrl && audioListener && isAudioContextInitialized && (
//             <PositionalAudio
//               ref={soundRef}
//               url={audioUrl}
//               distance={1}
//               // loop
//               autoplay={false}
//             />
//           )} */}

//           <AudioBlob sound={soundRef} play={play} />

//           <EffectComposer multisampling={0} enableNormalPass={false}>
//             <Bloom
//               mipmapBlur
//               luminanceThreshold={0.1}
//               luminanceSmoothing={0.99}
//               intensity={1.0}
//             />
//             <ToneMapping mode={CineonToneMapping} />
//           </EffectComposer>

//           {/* <OrbitControls maxDistance={10} minDistance={5} enableZoom /> */}
//         </Suspense>
//       </Canvas>
//       {/* <BlobDebugger sound={sound} play={play} /> */}
//     </div>
//   );
// };

// export default AudioBlobVisualizer;
import React, { useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera, PositionalAudio } from "@react-three/drei";
import {
  EffectComposer,
  Bloom,
  ToneMapping,
} from "@react-three/postprocessing";
import AudioBlob from "./AudioBlob";
import { AudioLoader } from "three";

const AudioBlobVisualizer = ({ audioUrl, playAudio, onAudioEnded }) => {
  const soundRef = useRef(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  console.log("audiourl", audioUrl);
  useEffect(() => {
    if (audioUrl && playAudio && soundRef.current) {
      const loader = new AudioLoader();

      // Load and decode the audio
      loader.load(
        audioUrl,
        (buffer) => {
          soundRef.current.setBuffer(buffer);
          soundRef.current.setRefDistance(1); // Optional: adjust audio distance
          soundRef.current.setLoop(false); // Ensure it does not loop
          soundRef.current.play();
          setIsAudioPlaying(true); // Start visualizer

          // Attach an ended event handler
          soundRef.current.source.onended = () => {
            setIsAudioPlaying(false); // Stop visualizer
            if (onAudioEnded) onAudioEnded();
          };
        },
        undefined,
        (error) => {
          console.error("Error loading audio file:", error);
        }
      );
    }

    return () => {
      if (soundRef.current) {
        soundRef.current.stop();
        soundRef.current.setBuffer(null); // Clear the buffer
      }
    };
  }, [audioUrl, playAudio, onAudioEnded]);

  return (
    <div style={{ width: "100%", height: "300px", overflow: "hidden" }}>
      <Canvas style={{ width: "100%", height: "100%" }}>
        <PerspectiveCamera makeDefault far={100} position={[0, 0, 8]} />
        {audioUrl && (
          <PositionalAudio
            ref={soundRef}
            url={audioUrl}
            distance={1}
            autoplay={false}
          />
        )}
        <AudioBlob sound={soundRef} play={isAudioPlaying} />
        <EffectComposer>
          <Bloom
            mipmapBlur
            luminanceThreshold={0.1}
            luminanceSmoothing={0.99}
            intensity={1.0}
          />
          <ToneMapping />
        </EffectComposer>
      </Canvas>
    </div>
  );
};

export default AudioBlobVisualizer;
